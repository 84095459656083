<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="5" order-sm="1">
				<v-subheader>Nueva receta</v-subheader>
				<v-divider class="mb-2"></v-divider>
        <NuevaReceta
          @reload="$emit('reload')"
          :key="keyHelper"
          :value="receta"
          :familias="familias"
          :categorias="categorias"
          :articulos="articulos"
          :escandallos="escandallos"
        />
      </v-col>
      <v-col cols="12" md="7" order-sm="1">
        <v-subheader>Listado recetas</v-subheader>
        <v-divider class="mb-2"></v-divider>
        <v-data-table
          fixed-header
          :items="recetas.filter(e => !loading)"
          :headers='recetasHeaderComp'
          item-key="idReceta"
          :single-select="false"
          :loading="loading"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [0, -1],
          }"
          checkbox-color="secondary"
        >
          <template v-slot:body.prepend="{ headers }">
            <table-filters
              :headers="headers"
              :items="recetas"
              v-model="inlineFilters"
            ></table-filters>
          </template>
          <template v-slot:item="{ item, index }">
            <tr style="z-index: 1;" hover>
              <td style="border-top: 3px solid var(--v-secondary-base);white-space: nowrap !important;padding-left: 22px !important;z-index: 10;">{{ index+1 }}</td>
              <td style="border-top: 3px solid var(--v-secondary-base);white-space: nowrap !important;padding-left: 22px !important;">{{ item.nombre }}</td>
              <td style="border-top: 3px solid var(--v-secondary-base);min-width: 200px;padding-left: 22px !important;" :title="item.categoriasShow">{{ item.categoriasShow }}</td>
              <template >
                <td v-for="ind in biggestItem" style="border-top: 3px solid var(--v-secondary-base);min-height: fit-content !important;">
                    {{ item.escandallos[ind-1] ? (articulos.filter(e => e.idArticulo == escandallos.filter(e => e.idEscandallo == item.escandallos[ind-1].idEscandallo).map(a => a.idArticulo)[0]).map(a => a.nombre)[0] + " (x" + item.escandallos[ind-1].uds + ")") : '-' }}
                </td>
              </template>
              <td style="border-top: 3px solid var(--v-secondary-base);white-space: nowrap !important;padding-left: 22px !important;" class="text-right">{{ pr(item.escandallos).toFixed(2).replace(".",",").replace(/\B(?=(\d{3})+(?!\d))/g, ".") }} €</td>
              <td style="border-top: 3px solid var(--v-secondary-base);white-space: nowrap !important;padding-left: 22px !important;">{{ item.nota }}</td>
              <td style="border-top: 3px solid var(--v-secondary-base);white-space: nowrap !important;padding-left: 22px !important;">{{ item.actualizado.toLocaleDateString('esp', { day : 'numeric', month : 'short', year : 'numeric' }).toLocaleUpperCase() }}</td>
              <td style="border-top: 3px solid var(--v-secondary-base);">
                <div class="d-flex">
                  <v-btn
                    @click="receta = item"
                    small
                    rounded
                    color="secondary"
                  >
                    <v-icon left>mdi-pencil</v-icon>
                    Editar
                  </v-btn>
                  <v-btn
                    @click="deleteReceta(item)"
                    small
                    class="ml-1"
                    rounded
                    color="error"
                  >
                    <v-icon left>mdi-delete</v-icon>
                    Eliminar
                  </v-btn>
                </div>
              </td>
            </tr>
            <tr style="color:var(--v-primary-base)">
              <td></td>
              <td></td>
              <td></td>
              <td v-for="ind in biggestItem" >
                {{ item.escandallos[ind-1] ? escandallos.filter(e => e.idEscandallo == item.escandallos[ind-1].idEscandallo).map(a => a.nombre)[0] : '' }}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
		<ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { parseDate,perColumnFilter } from "@/utils/index.js";

export default {
  props: {
    loading: Boolean,
    familias: Array,
    categorias: Array,
    articulos: Array,
    escandallos: Array,
    recetas: Array,
    recetasHeader: Array,
    biggestItem: Number,
  },
  components: {
    NuevaReceta: () => import("./NuevaReceta.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      receta: null,
      inlineFilters: {},
      keyHelper: 0,
    };
  },
  computed:{
    recetasHeaderComp(){
      return this.recetasHeader.map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-1 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      }))
    }
  },
  watch: {
    receta() {
      this.keyHelper++;
    },
  },
  methods: {
    pr(i){
      var total = 0
      i.forEach(esc => {
        try{
          // console.log(this.articulos.filter(a => a.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.nombre)[0] + " -> " + this.articulos.filter(a => a.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio)[0]+ " " + this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0] + " *" + esc.uds);
          total += eval(this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.formula)[0].replaceAll(',','.').replaceAll('p', this.articulos.filter(art => art.idArticulo == this.escandallos.filter(e => e.idEscandallo == esc.idEscandallo).map(a => a.idArticulo)[0]).map(a => a.precio))) * esc.uds
        }
        catch(e){
          total += 0
        }
      });
      return total
    },
    parseDate,
    async deleteReceta({ idReceta, nombre }) {
			const confirmation = await this.$refs.confirm.open(
				`Receta: ${nombre}`,
        `ATENCIÓN: Se eliminará la receta de los menús en los que se haya utilizado. ESTA ACCIÓN ES IRREVERSIBLE SIN UNA COPIA DE SEGURIDAD. ¿Seguro que quieres eliminar el receta?`,
        { color: "error" }
      );
      if (!confirmation) return;

      const { data } = await axios({
        method: "DELETE",
        url: "/recetas/" + idReceta,
      });
      this.$emit('reload')
    }
  },
};
</script>
